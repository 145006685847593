import React, {useEffect} from 'react'
import { navigate } from 'gatsby'

const index = () => {
  // window.location.replace('/en');
  useEffect(() => {
    navigate("/en")
  }, [])

  return <></>
}

export default index
